<template>
  <!-- 项目概况 -->
  <div class="supervise-schedule">
    <h2>课程表</h2>
    <Schedule></Schedule>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="less" scoped>
  .supervise-schedule {
    width: 990px;
    margin: 0 auto;

    h2 {
      margin: 20px auto 12px;
      font-size: 20px;
      font-weight: 600;
      color: #333333;
    }

    /deep/ .course-form {
      .el-calendar {
        min-width: 680px;
      }

      .aside .course-card .card .course-cover {
        display: block;
      }
    }
  }
</style>